import React, { Component } from 'react'
import './Contact.css'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Fade from '@material-ui/core/Fade'

class Contact extends Component {
	render() {
		return (
			<Fade in= {true}>
				<Grid container xs={12}>
					<Paper className="app-contact-welcome" elevation="5">
						<Grid item alignItems="center" className="app-contact-info">
							<Typography variant="h3" component="h3">CONTACT ME</Typography>
							<Typography variant="h4" component="subtitle">oscar kwan</Typography>
							<Typography><Link href="https://www.instagram.com/_oscarkwan/" underline="none">instagram.</Link></Typography>
							<Typography><Link href="https://github.com/Oscar-Kwan" underline="none">github.</Link></Typography>
							<Typography><Link href="https://www.linkedin.com/in/okwan/" underline="none">linkedin.</Link></Typography>
						</Grid>
					</Paper>
				</Grid>
			</Fade>
		)
	}
}

export default Contact
