import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Amplify from 'aws-amplify'
import config from './aws-exports'
import './index.css'
import App from './activities/App.jsx'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

Amplify.configure(config)


ReactDOM.render(<Router history={history}><App /></Router>, document.getElementById('root'))