import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { withStyles } from '@material-ui/core/styles'
import { getHotSauce } from '../graphql/queries'
import './Product.css'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CardMedia from '@material-ui/core/CardMedia'

const TabContainer = (props) =>{
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	)
}

const styles = {
	root: {
		backgroundColor: 'white',
	},
	indicator: {
		color: '#112F41'
	}
}

class Product extends Component {
	state = {
		hotSauce: [],
		value: 0
	}
    
	async componentDidMount() {
		try {
			const apiData = await this._handleGet()
			this.setState({ hotSauce: apiData.data.getHotSauce })            
		} catch (e) {
			alert(e)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.url !== prevProps.match.url) {
			this._handleGet().then((resp) =>{
				this.setState({ hotSauce: resp.data.getHotSauce})
			})
		}
	}

	_handleGet = () => {
		return API.graphql(graphqlOperation(getHotSauce, {id: this.props.match.params.id}))
	}

	_handleTabChange = (event, value) => {
		this.setState({ value })
	}

	render() {
		let { hotSauce, value } = this.state
		return (
			<Fade in={true}>
				<Grid container style={{ height: 'auto', flexGrow: '1' }} spacing={8}>
					<Grid item xs={12} md={6} lg={6}>
						<Paper style={{ margin: '8px'}}>
							<Typography variant="h3" component="h6" style={{ paddingTop: '36px', color: '#112F41'}}>
								<b>{hotSauce.name}</b>
							</Typography>
							<Typography component="p" style={{ color: '#F2B134', paddingTop: '12px'}}>
								<i>{hotSauce.scoville_units}</i>
							</Typography>
							<Fade in={true}>
								<Grid item xs ={12} container style={{ justifyContent: 'center', paddingBottom: '36px'}}>
									<CardMedia className="app-product-paper-picture" component="img" image={hotSauce.picture_url} />
								</Grid>
							</Fade>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6} lg={6} className="app-product-desc" style={{ color: 'white', height: '600px'}}>
						<Grid data={hotSauce} key={hotSauce.id} style={{ margin: '8px'}}>
							<AppBar position="static" className={this.props.classes.root}>
								<Tabs variant="fullWidth" className={this.props.classes.indicator} value={value} onChange={this._handleTabChange}>
									<Tab label="Information" />
									<Tab label="Fun Facts" />
								</Tabs>
							</AppBar>
							<Grid item>
								{value === 0 && 
								<TabContainer >
									<Typography variant="h3" component="h6" style={{ textAlign: 'left', color: '#112F41' }}>
										information
									</Typography>
									<Typography variant="h6" component="h6" className="app-product-paper-header">
										name
									</Typography>
									<Typography variant="body1" component="body1" className="app-product-paper-information">
										{hotSauce.name}
									</Typography>
									<Typography variant="h6" component="h6" className="app-product-paper-header">
										year
									</Typography>
									<Typography variant="body1" component="body1" className="app-product-paper-information">
										{hotSauce.year}
									</Typography>
									<Typography variant="h6" component="h6" className="app-product-paper-header">
										company
									</Typography>
									<Typography variant="body1" component="body1" className="app-product-paper-information">
										{hotSauce.company}
									</Typography>
									<Typography variant="h6" component="h6" className="app-product-paper-header">
										origin
									</Typography>
									<Typography variant="body1" component="body1"className="app-product-paper-information">
										{hotSauce.origin}
									</Typography>
									<Typography variant="h6" component="h6" className="app-product-paper-header">
										description
									</Typography>
									<Typography variant="body1" component="body1" className="app-product-paper-information">
										{hotSauce.description}
									</Typography>
								</TabContainer>
								}
								{value === 1 && 
									<TabContainer >
										<Typography variant="h3" component="h6" style={{ textAlign: 'left', color: '#112F41' }}>
											fun facts
										</Typography>
										<Typography variant="h6" component="h6" className="app-product-paper-header">
											scoville
										</Typography>
										<Typography variant="body1" component="body1" className="app-product-paper-information">
											{hotSauce.scoville_units}
										</Typography>
										<Typography variant="h6" component="h6" className="app-product-paper-header">
											amount in jalapeno peppers
										</Typography>
										<Typography variant="body1" component="body1" className="app-product-paper-information">
											{hotSauce.jalapeno}
										</Typography>
										<Typography variant="h6" component="h6" className="app-product-paper-header">
											amount in cayenne peppers
										</Typography>
										<Typography variant="body1" component="body1" className="app-product-paper-information">
											{hotSauce.cayenne}
										</Typography>
										<Typography variant="h6" component="h6" className="app-product-paper-header">
											amount in habanero peppers
										</Typography>
										<Typography variant="body1" component="body1" className="app-product-paper-information">
											{hotSauce.habanero}
										</Typography>
									</TabContainer>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Fade>
			
		)
	}
}

export default withStyles(styles)(Product)