// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	'aws_project_region': 'us-east-2',
	'aws_appsync_graphqlEndpoint': 'https://cj2skd55yze4toc4qqgd43fhiq.appsync-api.us-east-2.amazonaws.com/graphql',
	'aws_appsync_region': 'us-east-2',
	'aws_appsync_authenticationType': 'API_KEY',
	// "aws_appsync_apiKey": "da2-rdpnm3j7rve2lpxefjwlxzlvka"
	'aws_appsync_apiKey': 'da2-uw3a4shikjddzlaucd7xwqvf3a'
}


export default awsmobile
