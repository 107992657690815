import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './segments/Home'
import Contact from './segments/Contact'
import Product from './segments/Product'
import NotFound from './segments/NotFound'

export default () =>
	<Switch>
		<Route path="/" exact component={Home} />
		<Route path="/product/:id" component={Product} />
		<Route path="/contact" exact component={Contact} />
		<Route component={NotFound} />
	</Switch>