import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listHotSauces } from '../graphql/queries'
import { Link, withRouter } from 'react-router-dom'
import Routes from '../routes'
import './App.css'

import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'

class App extends Component {
	state = {
		hotSauces: [],
		selectedOption: null,
	}

	async componentDidMount() {
		try {
			const apiData = await API.graphql(graphqlOperation(listHotSauces))
			const hotSauces = apiData.data.listHotSauces.items

			let hotSauceNew = hotSauces.flatMap( item => {
				return { label: item.name, value: item.id }
			})

			this.setState({ hotSauces: hotSauceNew })
		} catch (e) {
			alert(e)
		}
	}

	_handleChange = (selectedOption) => {
		this.setState({ selectedOption })
		this.props.history.push(`/product/${selectedOption.value}`)
	}

	_handleReset = () => {
		this.setState({ selectedOption: null})
	}

	render() {
		let { selectedOption, hotSauces } = this.state

		return (
			<Grid className="app">
				<Grid className="app-header">
					<AppBar position="absolute" color="default" className="app-header-bar">
						<Toolbar>
							<Typography variant="h5" component="h3" color="inherit" noWrap className="app-header-title">
								<Link to='/' onClick={this._handleReset} className="link" >
									<span>SCOVILLE</span>
								</Link>
							</Typography>
							<SearchIcon style={{ color: 'white'}} />
							<div style={{ width: '150px'}}>
								<Select
									value={selectedOption}
									onChange={this._handleChange}
									options={hotSauces}
								/>
							</div>

						</Toolbar>
					</AppBar>
				</Grid>
				<Grid className="app-main" >
					<Routes />
				</Grid>
				<Grid className="app-footer">
					<Grid className="app-footer-left">
						<Typography variant="caption" component="h3" color="inherit" noWrap>
							<Link to='/contact' className="link" >
								<span>contact</span>
							</Link>
						</Typography>
					</Grid>
					<Grid className="app-footer-right">
						<Typography variant="caption" component="h3" >
							<span>made with <span role="img" aria-label="heart">❤️</span> by oscar kwan</span>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

export default withRouter(App)
