import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listHotSauces } from '../graphql/queries'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'

import './Home.css'

class Home extends Component {
	state = {
		hotSauces: []
	}

	async componentDidMount() {
		try {
			const apiData = await API.graphql(graphqlOperation(listHotSauces))

			const hotSauces = apiData.data.listHotSauces.items
			this.setState({ hotSauces })
		} catch (e) {
			alert(e)
		}
	}

	render() {
		let { hotSauces } = this.state

		return (
			<Grid container className="app-home-main">
				<Grid item xs={12} className="app-home-main-background" style={{ marginBottom: '200px', paddingTop:'100px'}}>
					<Grid className="app-home-main-title">
						<Typography variant="h2" component="h2" className="app-home-main-title-h3">
							<span role="img" aria-label="red-pepper">🌶️</span> SCOVILLE
						</Typography>
						<Typography variant="p" component="p" className="app-home-main-title-p">
							<b>FIND YOUR HOT SAUCE INFORMATION HERE</b>
						</Typography>
					</Grid>
				</Grid>
				{/* <Grid item xs={12}>
					<Typography variant="h4" component="h2" style={{ paddingLeft: '24px', paddingBottom:'24px', textAlign: 'left', color: 'black'}}>
						FEATURED SAUCES
					</Typography>
				</Grid> */}
				<Grid container spacing={8} style={{ flewGrow: 1, paddingBottom:'12px' }}>
					{hotSauces.slice(0,6).map((rest, i) => (
						<Fade in={true}>
							<Grid item xs={12} sm={6} md={4}>
								<Link to={{ pathname:`/product/${rest.id}`, param1: rest.id }} key={rest.id}>
									<Paper className="app-home-featured-sauce" data={rest} key={rest.id} elevation={5} >
										<Typography variant="h5" component="h3" style={{ color: '#ED553B', marginTop: '20px'}}>
											{rest.name}
										</Typography>
										<Typography component="p" style={{ color: '#112F41', padding: '15px', textAlign: 'left'}}>
											{rest.description}
										</Typography>
										<Typography component="p" style={{ color: '#0894A1', fontWeight:'bold', marginBottom: '20px', position:'absolute', bottom:'0', width:'100%'}}>
												READ MORE
										</Typography>
									</Paper>
								</Link>
							</Grid>
						</Fade>
					))}
				</Grid>
			</Grid>
		)
	}
}

export default Home
