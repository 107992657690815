import React, { Component } from 'react'
import './NotFound.css'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'

class NotFound extends Component {
	render() {
		return (
			<Fade in={true}>
				<Grid className="app-notfound">
					<Grid className="app-notfound-welcome">
						<Typography variant="h4" component="h3" style={{ paddingTop: '24px'}}>
									NOT FOUND
						</Typography>
						<Typography variant="p" component="p">
							Oops ... this isn't part of the application
						</Typography>
					</Grid>
				</Grid>
			</Fade>
		)
	}
}

export default NotFound
